<template>
  <v-footer
    id="home-footer"
    color="grey darken-4"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
            <template v-for="(s, i) in social">
              <a
                v-if="s.icon"
                :key="s.text"
                class="white--text pa-1 pa-md-0"
                :href="s.link"
                target="_blank"
              ><v-icon color="white">{{ s.icon }}</v-icon></a>
              <a
                v-else
                :key="s.text"
                class="white--text pa-1 pa-md-0"
                :href="s.link"
                target="_blank"
                v-text="s.text"
              />

              <v-responsive
                v-if="i < social.length -1"
                :key="`divider-${s}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>

        <v-col
          class="text-center text-md-right"
          cols="12"
          md="6"
        >
          &copy; 2022 Monica Bachmann &amp; Marketing Wegweiser GmbH
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        { text: 'Facebook', link: 'https://www.facebook.com/PraxisMonicaBachmann', icon: 'mdi-facebook' },
        { text: 'Instagram', link: 'https://www.instagram.com/praxismonicabachmann/', icon: 'mdi-instagram' },
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
